var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class TsUtil {
    static cloneObj(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    static getRandomString(len) {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let ret = '';
        for (let i = 0; i < len; i++) {
            const index = Math.floor(Math.random() * chars.length);
            ret += chars.substr(index, 1);
        }
        return ret;
    }
    static getRandomNumber(len) {
        const chars = '0123456789';
        let ret = '';
        for (let i = 0; i < len; i++) {
            const index = Math.floor(Math.random() * chars.length);
            ret += chars.substr(index, 1);
        }
        return ret;
    }
    static isEmptyObject(obj) {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }
    static hashStringToNumber(str) {
        let hash = 0, i, chr;
        if (str.length === 0)
            return hash;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
    static escapeHtml(str) {
        str = str.replace(/&/g, '&amp;');
        str = str.replace(/</g, '&lt;');
        str = str.replace(/>/g, '&gt;');
        return str.replace(/\n/g, '<br/>');
    }
    static isPhoneNumber(phone) {
        const c = phone.replace(/-/g, '');
        const isNum = /^\d+$/.test(c);
        if (!isNum)
            return false;
        if (c.length < 9)
            return false;
        if (c.substr(0, 1) !== '0')
            return false;
        return true;
    }
    static formatNumber(no) {
        if (!no)
            return 0;
        const minus = Number(no) < 0;
        let n = no + "";
        let r = "";
        const dotIndex = n.indexOf(".");
        if (dotIndex >= 0) {
            r = n.substr(dotIndex + 1);
            n = n.substr(0, dotIndex);
        }
        let ret = n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (minus) {
            ret = "-" + ret;
        }
        if (r !== "") {
            return ret + "." + r;
        }
        else {
            return ret;
        }
    }
    static limitNumber(n, maxLen) {
        const nn = ('' + n).replace(/\D/g, '');
        if (nn.length <= 0)
            return '';
        if (nn.length > maxLen)
            return nn.substr(0, maxLen);
        return nn;
    }
    static decimalPointRound(num, len) {
        return Math.round(Number(num) * (Math.pow(10, len))) / (Math.pow(10, len));
    }
    static formatPhone(s) {
        if (!s)
            return '';
        let s2 = ("" + s).replace(/\D/g, '');
        if (s2.length > 11) {
            s2 = s2.substr(0, 11);
        }
        if (s2.substr(0, 2) === "02") {
            if (s2.length === 9) {
                const m = s2.match(/^(\d{2})(\d{3})(\d{4})$/);
                return (!m) ? null : m[1] + "-" + m[2] + "-" + m[3];
            }
            else if (s2.length > 6) {
                const m = s2.match(/^(\d{2})(\d{4})(\d*)$/);
                return (!m) ? null : m[1] + "-" + m[2] + "-" + m[3];
            }
            else if (s2.length > 2) {
                const m = s2.match(/^(\d{2})(\d*)$/);
                return (!m) ? null : m[1] + "-" + m[2];
            }
            else {
                return s2;
            }
        }
        else {
            if (s2.length > 7) {
                const m = s2.match(/^(\d{3})(\d{4})(\d*)$/);
                return (!m) ? null : m[1] + "-" + m[2] + "-" + m[3];
            }
            else if (s2.length > 3) {
                const m = s2.match(/^(\d{3})(\d*)$/);
                return (!m) ? null : m[1] + "-" + m[2];
            }
            else {
                return s2;
            }
        }
    }
    static sleep(ms) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, ms);
            });
        });
    }
    static hexToRgbA(hex, alpha) {
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            let c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
        }
        return 'rgba(0,0,0,1)';
    }
    /**
     * string to number hash
     * @param value
     */
    static hashString(value) {
        if (value.length === 0)
            return 0;
        let hash = 0, chr;
        for (let i = 0; i < value.length; i++) {
            chr = value.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
    static hashStringToNumberWithMax(value, max) {
        const hash = this.hashString(value);
        return Math.abs(hash) % max;
    }
    static shuffleArray(array) {
        let currentIndex = array.length, randomIndex;
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]
            ];
        }
        return array;
    }
    static waitForObjectValue(obj, key, value, timeout, checkInterval = 500) {
        return __awaiter(this, void 0, void 0, function* () {
            const startTime = Date.now();
            while (Date.now() < startTime + timeout) {
                if (obj[key] === value)
                    return true;
                yield this.sleep(checkInterval);
            }
            return false;
        });
    }
}
